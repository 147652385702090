#our-recipients {
    .avt-card-frame {
        &>div {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                right: 69px;
                top: 70px;
                width: 45px;
                height: 45px;
                z-index: 1;
                background-size: contain;
                background-repeat: no-repeat;
            }  
        }
    }
    #chart_div43 {
        &:before {
            background-image: url('../images/svg/ico-woman-man.svg');
        }
    }
    #chart_div44 {
        &:before {
            background-image: url('../images/svg/ico-people.svg');
            top: 78px;
        }
    }
    #chart_div45 {
        &:before {
            background-image: url('../images/svg/ico-map-marker.svg');
        }
    }
    #chart_div46 {
        &:before {
            background-image: url('../images/svg/ico-phone-device.svg');
        }
    }
    #chart_div47 {
        &:before {
            background-image: url('../images/svg/ico-creative-process.svg');
        }
    }
}