body.close {
    overflow-y: hidden;
    height: 100vh;
    @media(min-width: $breakpoint-desktop-min) {
        overflow-y: initial !important;
        height: auto !important;
        padding-right: 0 !important;
    }
}

header{
    height: $height-header;
    background-color: $bg-header;
    &.opacity-header {
        position: absolute;
        z-index: 9;
        width: 100%;
        top:0;
        left: 0;
    }
    .avt-logo{
        height: $height-header;
        img{
            display: block;
            height: 100%;
        }
    }

    .menu-toggle {
        position: absolute;
        z-index: 1020;
        top: 24px;
        right: 33px;
        color: #fff;
        font-size: 15px;
        font-weight: 600;
        cursor: pointer;

        &.show-menu {
            color: #1A1B1C;

            .bar {
                background: #1A1B1C;

                &.bar-1 {
                    transform: rotate(45deg);
                }

                &.bar-2 {
                    opacity: 0;
                }

                &.bar-3 {
                    transform: rotate(-45deg);
                }
            }
        }

        .bars {
            display: inline-block;
            width: 16px;
            height: 9px;
            position: relative;
            vertical-align: middle;
            margin-bottom: 3px;
        }

        .bar {
            display: block;
            width: 100%;
            height: 2px;
            background: #fff;
            position: absolute;
            left: 0;
            transition: transform .25s ease 0s,transform-origin .25s ease 0s;

            &.bar-1 {
                top: 0;
                transform-origin: 2px 0;
            }

            &.bar-2 {
                top: 4px;
            }

            &.bar-3 {
                top: 8px;
                transform-origin: 2px 2px;
            }
        }
    }

    .menu-wrap {
        transform: translateZ(0);
        will-change: transform;
        @media(max-width: $breakpoint-mobile-max) {
            padding: 64px 16px 20px;
        }
        @media(max-width: $breakpoint-tablet-max) {
            padding: 64px 25px 20px;
            width: calc(100% - 90px);
            max-width: 290px;
            position: fixed;
            top: 0;
            bottom: 0;
            z-index: 999;
            left: 100%;
            background: #fff;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            text-align: left;
            transition-property: transform;
            transition-timing-function: ease-out;
            transition-delay: 0s;
        }
        @media(min-width: $breakpoint-desktop-min) {
            transform: none !important;
            transition-duration: 0s !important;
        }
    }
}
