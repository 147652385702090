.avt-news-box {
    margin-bottom: 56px;
    @media(min-width: $breakpoint-desktop-large-min) {
        margin-bottom: 0;
    }
    .avt-news-description {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        height: 100%;
        text-decoration: none;
        .avt-date {
            font-weight: 500;
            font-size: 15px;
            font-weight: 500;
            display: block;
            width: 100%;
        }
        .avt-title {
            display: block;

            color: $color-font-main;
            font-size: 22px;
            font-weight: 600;
        }
    }
    .avt-news-image {
        width: 141px;
        height: 141px;
        display: flex;
        border: 1px solid rgba(0,0,0,0.2);
        padding: 2px;
        img {
            height: 100%;
            margin: 0 auto;
            display: block;
        }
    }

}
