.avt-section-kiosk {
    position: relative;
    @media(min-width: $breakpoint-tablet-min) {
        &:after {
            content: "";
            display: block;
            height: 33%;
            position: absolute;
            bottom: 0;
            left:0;
            right: 0;
            background: $bg-main;
        }
    }
    .avt-kiosk-slider {
        padding: 100px 0;
        .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 32px;
            padding-bottom: 32px;
            @media(min-width: $breakpoint-desktop-large-min){
                box-shadow: 0 19px 38px rgba(0,0,0,0.15), 0 15px 12px rgba(0,0,0,0.11);
                background-color: $bg-main;
            }
            .avt-image {
                @media(min-width: $breakpoint-desktop-large-min){
                    width: 50%;
                    float: left;
                }
                img{
                    width: 85%;
                    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
                    @media(min-width: $breakpoint-desktop-large-min){
                        width: 80%;
                    }
                    @media(min-width: 1600px){
                        margin-top: -100px;
                    }
                }
            }
            .avt-description {
                display: none;
                @media(min-width: $breakpoint-desktop-large-min){
                    display: flex;
                    width: 50%;
                    float: left;
                    padding-right: 30px;
                    .avt-title {
                        font-size: 28px;
                        font-weight: 700;
                    }
                    .avt-subtitle {
                        font-size: 17px;
                        font-weight: 400;
                        margin-bottom: 40px;
                    }
                    .avt-descr {
                        padding-bottom: 32px;
                        border-bottom: 1px solid rgba(0,0,0,0.2);
                        line-height: 30px;
                    }
                    .avt-link {
                        font-size: 13px;
                        font-weight: 700;
                        display: inline-block;
                        width: 100%;
                        text-align: center;
                        text-decoration: none;
                        color: $color-font-main;
                        text-transform: uppercase;
                        img{
                            margin-right: 10px;
                            display: inline-block;
                            width: 15px;
                            margin-bottom: 4px;
                        }
                    }
                }
            }
        }
    }
}
