.avt-button-line {
    position: relative;
    &:after, &:before{
        content: "";
        position: absolute;
        display: block;
        width: 20%;
        height: 25%;
        border-bottom: 1px solid rgba(0,0,0,0.2);
        bottom:50%;
        @media(min-width: $breakpoint-desktop-min) {
            width: 27.5%;
        }
        @media(min-width: $breakpoint-desktop-large-min) {
            width: 30%;
        }
    }
    &:before{
        left:0;
        border-left: 1px solid rgba(0,0,0,0.2);
        @media(min-width: $breakpoint-desktop-large-min){
            left: 2.5%;
        }
    }
    &:after{
        right:0;
        border-right: 1px solid rgba(0,0,0,0.2);
        @media(min-width: $breakpoint-desktop-large-min){
            right: 2.5%;
        }
    }
}
