// Dla Maksyma :*
.container-fluid {
    &.w {
        max-width: 1200px;
    }
}

// Dziala jak container-fluid w bootstrap
.avt-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

// Dziala jak container w bootstrap ale ze stalym max-width
.avt-container-1200 {
    @extend .avt-container;
    max-width: 1230px;
}

.avt-container-1400 {
    @extend .avt-container;
    max-width: 1430px;
}

.avt-container-1920 {
    @extend .avt-container;
    max-width: 1920px;
}
