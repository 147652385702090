.avt-news-article {
    text-align: center;
    .avt-article-date {
        margin-top: 32px;
        font-weight: 700;
        text-align: center;
        display: block;
        color: $color-font-additional;
        font-size: 18px;
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 25px;
            margin-top: 48px;
        }
    }
    .avt-article-header {
        margin-top: 32px;
        margin-bottom: 0;
        font-weight: 400;
        text-align: center;
        font-size: 22px;
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 45px;
            margin-top: 48px;

        }
    }
    .avt-article-lead-text {
        margin: 32px auto 0;
        max-width: 900px;
        font-size: 16px;
        font-weight: 600;
        line-height: 35px;
        text-align: center;
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 22px;
            text-align: left;
            margin-top: 48px;

        }

    }
    .avt-bg-quotation {
        margin: 32px auto 0;
        position: relative;
        display: inline-block;
        text-align: left;
        max-width: 420px;
        @media(min-width: $breakpoint-tablet-min) {
            max-width: 500px;
            margin-top: 48px;

        }

        &:before{
            content: "";
            position: absolute;
            display: block;
            width:50px;
            height: 50px;
            background-size: cover;
            background-image: url("../images/quot.png");
            @media(min-width: $breakpoint-tablet-min) {
                width: 100px;
                height: 100px;
                top: -12.5px;
                left: -12.5px;
            }

        }
    }
    .avt-img {
        margin: 32px auto 0;
        max-width: 900px;
    }
    .avt-article-text {
        margin: 32px auto 0;
        max-width: 900px;
        font-size: 15px;
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 21px;
            margin-top: 48px;
            text-align: left;
        }
        .avt-article-link {
            color: $color-font-additional;
        }
        .imagesInContent {
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
    .avt-article-image {
        margin-top: 32px;
        @media(min-width: $breakpoint-tablet-min) {
            margin-top: 48px;
        }
        img{
            display: block;
            margin: 0 auto;
        }
    }
    .avt-article-image-360 {
        @extend .avt-article-image;
        img{
            width: 100%;
            max-width: 360px;
        }
    }
}
