//Zmienia paddingi w bootstrap

.avt-bt {
    .avt-container-1400{
        padding-left: 25px;
        padding-right: 25px;
        max-width: 1450px;
        .row {
            margin-left: -25px;
            margin-right: -25px;
            div[class^="col-"] {
                padding-left: 25px;
                padding-right: 25px;
            }
        }
    }
}
