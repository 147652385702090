header.super-header {
    height: 70px;
    background: #2c3243;
    
    
    nav {
        @media(max-width: $breakpoint-tablet-max) {
            position: relative;
            padding: 0 0 50px;
        }
    }
    
    .in-header {
        position: relative;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 0 0 85px;
        @media(min-width: $breakpoint-desktop-min) {
            padding: 0 15px 0 95px;
        }
    }
    
    .logo {
        position: absolute;
        top: 0;
        left: 0;
        @media(min-width: $breakpoint-desktop-min) {
            left: 15px;
        }
        img {
            position: absolute;
        }
        .logo-white {
            z-index: 1;
        }
        .clip-logo {
            z-index: 2;
            clip: rect(0px, 90px, 90px, 0px);
            // transition: clip 0.2s cubic-bezier(.43,0,.9,.31);
            transition: clip 0.25s ease;
        }
        &:hover {
            .clip-logo {
                clip: rect(0px, 90px, 90px, 90px);
            }
        }
    }
    
    .main-link {
        @media(max-width: $breakpoint-tablet-max) {
            position: absolute;
            bottom: 0;
            left: 0;
        }
        @media(min-width: $breakpoint-desktop-min) {
            float: right;
            border-left: 2px solid #53596d;
            height: 40px;
            line-height: 40px;
            padding: 0 0 0 25px;
            margin: 16px 0 0;
        }
        @media(min-width: $breakpoint-desktop-large-min) {
            padding: 0 0 0 33px;
        }
        
        a {
            position: relative;
            display: inline-block;
            color: #fff;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 600;
            padding: 0 32px 0 0;
            text-decoration: none;
            @media(max-width: $breakpoint-tablet-max) {
                color: #1A1B1C;
            }
            
            &:hover {
                svg {
                    transform: translateX(6px);
                }
            }
            
            svg {
                position: absolute;
                right: 0;
                top: 50%;
                margin-top: -8px;
                transition: transform 0.25s ease 0s;
                @media(max-width: $breakpoint-tablet-max) {
                    g {
                        fill: #1A1B1C !important;
                    }
                }
            }
        }
    }
    
    #menu {
        padding: 0;
        > li.active {
            > a:after {
                width: 100%;
            }
        }
        
        @media(max-width: $breakpoint-tablet-max) {
            > li {
                &:nth-child(6) {
                    display: none;
                }

                + li {
                    margin: 24px 0 0;
                }
                > span {
                    color: #d51a18;
                    display: inline-block;
                    position: relative;
                    width: 18px;
                    height: 16px;
                    font-weight: bold;
                    line-height: 1;
                    font-size: 20px;
                    cursor: pointer;

                    &:before,
                    &:after {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 10px;
                        height: 100%;
                        transition: all 0.35s ease 0s;
                    }
                    &:after {
                        content: '-';
                        opacity: 0;
                        transform: rotate(-90deg);

                    }

                    &:before {
                        content: '+';
                    }

                    &.open {
                        &:after {
                            opacity: 1;
                            transform: rotate(0);
                        }

                        &:before {
                            opacity: 0;
                            transform: rotate(90deg);
                        }
                    }
                    
                    &.menu-spacing {
                        cursor: default;
                        
                        &:before,
                        &:after {
                            display: none;
                            
                        }
                    }
                }

                > a {
                    font-size: 18px;
                    color: #1A1B1C;
                    font-weight: bold;
                    line-height: 1;
                    text-decoration: none;
                }

                
                ul {
                    padding: 0 0 0 23px;

                    li {
                        margin: 7px 0 0;

                        a {
                            display: inline-block;
                            padding: 6px 16px 8px;
                            font-size: 17px;
                            line-height: 24px;
                            text-decoration: none;
                            color: $color-font-main;
                            @media(min-width: $breakpoint-mobile-min) {
                                white-space: nowrap;
                            }

                            .anim-bg {
                                background: rgba(255, 255, 255, 0.7);
                            }

                            .nav-text {
                                position: relative;
                                z-index: 2;

                                span {
                                    position: absolute;
                                    z-index: 1;
                                    top: 0;
                                    left: 0;
                                    bottom: 0;
                                    width: 0;
                                    overflow: hidden;
                                    color: #d51a18;
                                    transition: width .25s ease-out 0s;
                                    text-align: left;
                                }
                            }

                            &:hover {
                                .nav-text {
                                    span {
                                        width: 100%;
                                    }
                                }
                            }
                        }

                        &.active {

                            a {
                                background: #fff;
                                color: #d51a18;

                                .nav-text {
                                    span {
                                        display: none;
                                    }
                                }

                                .anim-bg {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        
        @media(min-width: $breakpoint-desktop-min) {
            float: right;
            margin: 24px 0 0;

            > li {
                float: left;
                margin: 0 33px 0 0;

                &:nth-child(6) {
                    display: none;
                }

                > a {
                    color: #a7aab1;
                    font-size: 15px;
                    line-height: 1;
                    font-weight: 500;
                }

                ul {
                    display: none;
                }
            }
        }
    }
    
    .menu-wrap {
        @media(max-width: $breakpoint-tablet-max) {
            background: #F1F1F1;
            max-width: 380px;
        }
    }
}

.site-close {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition-property: opacity;
    transition-timing-function: ease-out;
    transition-delay: 0s;
    @media(min-width: $breakpoint-desktop-min) {
        display: none !important;
    }
}