.avt-segment-home {
    background-color: $bg-home;
    .avt-segment-info {
        text-align: center;
        max-width: 900px;
        margin: 0 auto;
    }
    .avt-main-header {
        color: $color-font-second;
    }
    .avt-lead-text {
        color: $color-font-second;
        margin-bottom: 0;
        opacity: 0.5;
        margin-bottom: 32px;
        font-size: 16px;
    }
    .avt-btn-border {
        display: inline-block;
        margin: 7.5px auto;
        @media(min-width: $breakpoint-mobile-min) {
            margin: 15px 7.5px;
        }
    }
}
.avt-section-statistics {
    .avt-statistic-category {
        font-size: 0px;
        text-align: center;
        vertical-align: top;
        .avt-category-btn {
            text-align: center;
            font-size: 16px;
            display: inline-block;
            text-transform: uppercase;
            font-weight: 700;
            padding: 0 10px;
            @media(min-width: 380px) {
                padding: 0 15px;
            }
            @media(min-width: $breakpoint-mobile-min) {
                padding: 0;
                width: 150px;
            }
            @media(min-width: $breakpoint-tablet-min) {
                width: 200px;
            }
            @media(min-width: $breakpoint-desktop-min) {
                width: 250px;
            }
            a {
                color: $color-font-main;
                text-decoration: none;
                &:hover{
                    border-bottom: 3px solid $color-red;
                }
            }
            &.active {
                a {
                    border-bottom: 3px solid $color-red;
                }
            }
        }
    }
}
.avt-marks-isotope {
    .avt-header-isotope {
        width: 100%;
        @media(min-width: $breakpoint-tablet-min) {
            border-bottom: 2px solid $color-font-main;
            margin-bottom: 24px;
        }
        .avt-section-header {
            @media(min-width: $breakpoint-mobile-min) {
                text-align: left;
            }
            @media(min-width: $breakpoint-tablet-min) {
                width: 50%;
                float: left;
                border-bottom: none;
            }
        }
    }
    .element-item {
        padding-left: 25px;
        padding-right: 25px;
    }
    .filter-button-group {
        padding: 1px;
        text-align: center;
        position: relative;
        overflow: hidden;
        height: 44px;
        width: 100%;
        transition-duration: 400ms;
        margin-top: 24px;
        margin-bottom: 24px;
        @media(min-width: $breakpoint-tablet-min){
            width: 50%;
            float: right;
            margin: 0 0 0.5rem 0;
        }
        @media(min-width: $breakpoint-desktop-min){
            height: auto;
            width: auto;
        }
        &.open {
            height: auto;
            .avt-filter-btn {
                img{
                    transform: rotate(180deg);
                }
            }
        }
        &.avt-filter-top {
            margin-bottom: 16px;
        }
        &.avt-filter-bot {
            margin-bottom: 56px;
            @media(min-width: $breakpoint-desktop-min){
                display: flex;
                margin: 0 0 56px;
            }
            button{
                flex-grow: 1;
            }
        }
        .avt-filter-btn{
            transition-duration: 400ms;
            position: absolute;
            width: 40px;
            height: 43.1px;
            top: 1px;
            right: 1px;
            background-color: $bg-button-main;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            @media(min-width: $breakpoint-desktop-min){
                display: none;
            }
        }
        button {
            display: block;
            width: 100%;
            color: $color-font-second;
            background-color: $bg-button-main;
            font-size: 16px;
            border: none;
            min-height: 44px;
            padding: 0 10px;
            border-bottom: 1px solid $color-gray;
            font-weight: 600;
            outline: 0;
            @media(min-width: $breakpoint-desktop-min){
                color: $color-font-main;
                background-color: $bg-main;
                display: inline-block;
                width: auto;
            }
            &:hover, &:active, &:focus {
                background-color: $bg-button-main;
                color: $color-font-second;
                outline: 0;
            }
            &.active {
                background-color: $bg-button-second;
                width: calc(100% - 40px);
                @media(min-width: $breakpoint-desktop-min){
                    background-color: $bg-button-main;
                    color: $color-font-second;
                    width: auto;
                }

            }
        }
        button[data-filter='']{
            border-top: none;
        }
    }
}
