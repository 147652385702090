.avt-background-image{
    position: fixed;
    top:0;
    left: 0;
    bottom:0;
    right: 0;
    overflow: hidden;
    background-color: $bg-photos;
    z-index: -1;
    max-width: 100vw;
    max-height: 100vh;
    img{
        z-index: -1;
    }
}
