.avt-marks-modal {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 1001;
    background-color: $bg-main;
    position: fixed;
    height: 90vh;
    width: 90vw;
    max-width: 660px;
    display: none;
    .avt-exit-btn {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        img {
            width: 27px;
            height: auto;
        }
    }
    .avt-modal-box {
        padding: 20px;
        height: 100%;
    }
    .avt-modal-title {
        color: $color-font-additional;
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
    }
    .avt-list-item {
        margin-top: 16px;
        margin-bottom: 16px;
        border-bottom: 2px solid rgba(0,0,0,0.2);
        padding-bottom: 8px;
    }
    .avt-main-list {
        margin-top: 32px;
        overflow-y: scroll;
        height: 90%;
        padding: 0 30px;
        & > li {
            &:last-child{
                margin-bottom: 32px;
            }
        }
    }
}
