/* Bootstrap */
@import "vendors/bootstrap-4";
/* Swiper Slider */
@import "vendors/swiper.min";

/* Reset */
@import "abstracts/variables";
@import "abstracts/functions";
@import "base/typography";
@import "base/reset";

/* Layout */
@import "layout/header";
@import "layout/reklama-header";
@import "layout/navigation";
@import "layout/footer";
@import "layout/grid";
@import "layout/reklama-pages";

/* Komponenty */
@import "components/pagination";
@import "components/avt-bt";
@import "components/buttons";
@import "components/containers";
@import "components/avt-data-box";
@import "components/avt-statistic-box";
@import "components/avt-category-box";
@import "components/avt-card";
@import "components/avt-background-image";
@import "components/avt-kiosk-slider"; //DO ZMIANY/USUNIECIA
@import "components/avt-grid-icons"; //Inna nazwa?
@import "components/avt-circle-box";
@import "components/avt-header-line";
@import "components/avt-job-offer";
@import "components/avt-news-box";
@import "components/avt-catalog-btn";
@import "components/avt-isometric-grid";
@import "components/avt-marks-modal";
@import "components/avt-circle-icon";
@import "components/avt-button-line";
@import "components/avt-news-article";
@import "components/avt-article-nav";
@import "components/avt-bs-hover";
@import "components/our-marks-grid";
@import "components/avt-offer-items";
@import "components/avt-section-publicity";
@import "components/avt-reklama-grid";
@import "components/avt-reklama-nav";
@import "components/avt-reklama-charts";
@import "components/avt-text-blocks";
@import "components/avt-table-slider";
@import "components/avt-reklama-big-title";
@import "components/avt-animation-border";
@import "components/avt-animation-background";
@import "components/avt-animation-line";


@import "components/avt-fotopolis-slider";
@import "components/avt-slider-netflix";
@import "components/swiper-slider";
@import "components/footer-slider";
@import "components/avt-isotope";
@import "components/avt-news-slider";
@import "components/google-map";

/* Strony */
@import "pages/home";
@import "pages/portfolio";
@import "pages/portfolio-detail";
@import "pages/about";
@import "pages/segment";
@import "pages/news";
@import "pages/job-offers";
@import "pages/contact";
@import "pages/news-detail";
@import "pages/job-offer";
@import "pages/reklama";
@import "pages/reklama-products";
@import "pages/reklama-mass-media";
@import "pages/target-groups";
@import "pages/reklama-materials";
@import "pages/reklama-casestudy";
@import "pages/reklama-article";
@import "pages/reklama-contact";
