body {
    font-family: $font-stack;
    color: $color-font-main;
    &.avt-stop-scroll {
        // position: fixed;
        &:after{
            content: "";
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            position: fixed;
            background-color: rgba(0,0,0,0.6);
            z-index: 1000;
        }
    }
}
* {
    list-style: none;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

ul,ol{
    margin: 0;
    padding: 0;
}

.clear {
    display: table;
    clear: both;
}
