.avt-casestudy {
    .case-wrap {
        max-width: 1024px;
    }
    .casestudy-h1 {
        margin: 0 0 25px;
        color: #fff;
        font-size: 30px;
        font-weight: 300;
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 36px;
        }
        @media(min-width: $breakpoint-desktop-min) {
            font-size: 45px;
        }
        
        span {
            display: block;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 18px;
            @media(min-width: $breakpoint-tablet-min) {
                font-size: 20px;
            }
        }
    }
    
    .casestudy-h2 {
        font-size: 30px;
        line-height: 28px;
        margin: 0 0 28px;
        text-align: center;
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 36px;
            line-height: 33px;
            margin: 0 0 44px;
        }
        @media(min-width: $breakpoint-desktop-min) {
            font-size: 55px;
            line-height: 50px;
            margin: 0 0 64px;
        }
    }
    
    .video-block {
        padding: 36px 0 40px;
        background: #242c37;
        @media(min-width: $breakpoint-desktop-min) {
            padding: 36px 0 100px;
        }
        
        .wrap {
            max-width: 850px;
            margin: 0 auto;
        }
    }
    
    .description-block {
        background: #DBE3E5;
        padding: 58px 0;
        
        .avt-text-block {
            text-align: center;
        }
    }
    
    .summary {
        background: #EAEBEC;
        padding: 58px 0;
        text-align: center;
        @media(min-width: $breakpoint-tablet-min) {
            text-align: left;
        }
        @media(min-width: $breakpoint-desktop-min) {
            padding: 72px 0;
        }
        
        .num {
            font-size: 60px;
            font-weight: bold;
            line-height: 1;
            @media(min-width: $breakpoint-tablet-min) {
                font-size: 75px;
            }
            @media(min-width: $breakpoint-desktop-min) {
                font-size: 90px;
            }
            span {
                font-size: 35px;
                @media(min-width: $breakpoint-tablet-min) {
                    font-size: 45px;
                }
                @media(min-width: $breakpoint-desktop-min) {
                    font-size: 60px;
                }
            }
        }
        
        .num-title {
            font-size: 15px;
            font-weight: bold;
            line-height: 1;
            margin: 0 0 28px;
            @media(min-width: $breakpoint-tablet-min) {
                margin: 0;
            }
            @media(min-width: $breakpoint-desktop-min) {
                font-size: 19px;
            }
        }
    }
    
    .other-actions {
        padding: 58px 0;
        text-align: center;
        @media(min-width: $breakpoint-desktop-min) {
            padding: 72px 0;
        }
        
        .other-actions-swiper {
            margin: 32px auto 0;
            @media(min-width: $breakpoint-desktop-min) {
                margin: 80px auto 0;
            }
        }
    }
}