.avt-circle-box{
    position: absolute;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    .avt-circle-lg{
        top:0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        position: absolute;
        width: 350px;
        height: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        box-shadow: 0px 0px 74.7px 15.3px rgba(0, 0, 0, 0.22);
        @media(min-width: $breakpoint-desktop-large-min) {
            width: 485px;
            height: 485px;
        }
    }
    .avt-circle{
        height: 60%;
        width: 60%;
        border-radius: 50%;
        background-color: $bg-second;
        box-shadow: 0px 0px 74.7px 15.3px rgba(0, 0, 0, 0.22);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .avt-logo{
        width: 100px;
        text-align: center;
        img{
            width: 100%;
        }
    }
    
    &.reklama-page {
        .avt-circle-lg {
            background: #5d6377;
            box-shadow: 10px 10px 99px rgba(0, 0, 0, 0.16)
        }
    }
}
