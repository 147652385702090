@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-SemiBoldItalic.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-SemiBoldItalic.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-SemiBoldItalic.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-SemiBoldItalic.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-SemiBoldItalic.svg#Poppins-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-MediumItalic.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-MediumItalic.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-MediumItalic.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-MediumItalic.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-MediumItalic.svg#Poppins-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-Regular.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-Regular.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-Regular.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-Regular.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-BoldItalic.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-BoldItalic.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-BoldItalic.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-BoldItalic.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-BoldItalic.svg#Poppins-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-ThinItalic.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-ThinItalic.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-ThinItalic.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-ThinItalic.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-ThinItalic.svg#Poppins-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-BlackItalic.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-BlackItalic.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-BlackItalic.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-BlackItalic.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-BlackItalic.svg#Poppins-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-ExtraBoldItalic.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-ExtraBoldItalic.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-ExtraBoldItalic.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-ExtraBoldItalic.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-ExtraBoldItalic.svg#Poppins-ExtraBoldItalic') format('svg');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-Thin.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-Thin.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-Thin.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-Thin.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-Thin.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-Thin.svg#Poppins-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-ExtraBold.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-ExtraBold.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-ExtraBold.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-ExtraBold.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-ExtraBold.svg#Poppins-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-LightItalic.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-LightItalic.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-LightItalic.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-LightItalic.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-LightItalic.svg#Poppins-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-Black.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-Black.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-Black.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-Black.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-Black.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-Black.svg#Poppins-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-SemiBold.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-SemiBold.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-SemiBold.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-ExtraLight.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-ExtraLight.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-ExtraLight.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-ExtraLight.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-ExtraLight.svg#Poppins-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-Medium.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-Medium.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-Medium.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-Medium.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-Bold.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-Bold.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-Bold.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-Bold.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-ExtraLightItalic.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-ExtraLightItalic.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-ExtraLightItalic.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-ExtraLightItalic.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-ExtraLightItalic.svg#Poppins-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-Light.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-Light.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-Light.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-Light.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-Light.svg#Poppins-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.avt.pl/fonts/Poppins-Italic.eot');
    src: url('https://cdn.avt.pl/fonts/Poppins-Italic.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Poppins-Italic.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Poppins-Italic.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Poppins-Italic.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Poppins-Italic.svg#Poppins-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}


$font-stack: ('Poppins', sans-serif);

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/Icommon/icomoon.eot?4rcf8r');
  src:  url('../fonts/Icommon/icomoon.eot?4rcf8r#iefix') format('embedded-opentype'),
    url('../fonts/Icommon/icomoon.ttf?4rcf8r') format('truetype'),
    url('../fonts/Icommon/icomoon.woff?4rcf8r') format('woff'),
    url('../fonts/Icommon/icomoon.svg?4rcf8r#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-youtube:before              { content: "\e900"; }

.icon-twitter:before              { content: "\e901"; }

.icon-instagram:before            { content: "\e903"; }

.icon-facebook:before             { content: "\e904"; }

.icon-new-window:before           { content: "\e902"; }

.icon-close:before                { content: "\e905"; }

.icon-basic_webpage_txt:before    { content: "\e906"; }

.icon-basic_tablet:before         { content: "\e907"; }

.icon-basic_spread:before         { content: "\e908"; }

.icon-arrow-right:before          { content: "\e909"; }

.icon-arrow-left:before           { content: "\e909"; transform: rotate(180deg); display: block; }

.icon-arrow-long:before           { content: "\e90a"; }

.icon-angle-right:before          { content: "\e90b"; }

h1 {
    font-weight: 700;
    text-align: center;
    font-size: 30px;
    @media (min-width: $breakpoint-tablet-min) {
        font-size: 50px;
    }
    @media(min-width: $breakpoint-desktop-min) {
        font-size: 60px;
    }
}
h2 {
    font-size: 30px;
    font-weight: 400;
    @media (min-width: $breakpoint-tablet-min) {
        font-size: 36px;
    }
}
h2.rek-title {
    margin: 0 0 32px;
    font-size: 22px;
    color: #fff;
    text-align: center;
    font-weight: 600;
    @media (min-width: $breakpoint-tablet-min) {
        font-size: 28px;
    }
    @media (min-width: $breakpoint-desktop-min) {
        font-size: 35px;
    }
    &.black {
        color: #1A1B1C;
    }
}
p{
    font-size: 15px;
    line-height: 30px;
    @media (min-width: $breakpoint-desktop-min) {
        font-size: 17px;
        line-height: 33px;
    }

}
.avt-lead-text{
    font-size: 20px;
    line-height: 35px;
    font-weight: 600;
}
a {
    color: $color-font-main;
}
