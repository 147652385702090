#avt-fotopolis-slider {
    .avt-info{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left:0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .swiper-pagination {
        display: none;
        @media(min-width: $breakpoint-tablet-min){
            display: block;
            bottom: 42px;
        }
    }
    .swiper-pagination-bullet {
        width: 100px;
        margin: 0 32px;
    	display: inline-block;
    	opacity: .2;
        background: none;
        img{
            width: 100%;
        }
    }
    .swiper-pagination-bullet-active {
        opacity: 1;
    }
    .swiper-button-next, .swiper-button-prev{
        background-image: none;
    }
    .avt-bg{
        .mobile{
            @media(min-width: $breakpoint-desktop-min){
                display: none;
            }
        }
        .desktop{
            display: none;
            @media(min-width: $breakpoint-desktop-min){
                display: block;
            }
        }
        img{
            width: 150%;
            @media(min-width: $breakpoint-mobile-min){
                width: 100%;
            }
        }
    }
    .avt-fotopolis-header {
        font-weight: 700;
        font-size: 20px;
        width: 100%;
        top: 20px;
        position: absolute;
        z-index: 999;
        text-align: center;
        span {
            color: $color-font-additional;
        }
        svg {
            fill: $color-font-second;
            width: 40px;
            height: 40px;
            @media(min-width: $breakpoint-tablet-min){
                display: none;
            }
        }
    }
    .avt-fotopolis-slider-header {
        color: $color-font-second;
        text-align: center;
        margin-top: 64px;
        margin-bottom: 48px;
        @media(min-width: $breakpoint-tablet-min){
            margin-top: 160px;
        }
    }
    .avt-fotopolis-description {
        color: $color-font-second;
        text-align: center;
        max-width: 662px;
        margin: 0 auto;
        @media(min-width: $breakpoint-tablet-min){
            margin-bottom: 160px;
        }
    }
    .avt-logo-box {
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 100%;
        }
        svg {
            width: 100%;
            fill: $color-font-second;
        }
    }
    .avt-slide-logo {
        display: none;
    }
}
