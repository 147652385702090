.mass-media-list {
    .mass-media-item {
        font-size: 16px;
        
        span {
            display: block;
        }
        
        .category {
            font-size: 15px;
            text-transform: uppercase;
        }
        
        .num {
            font-size: 50px;
            color: #111111;
            line-height: 60px;
        }
        
        
        .title {
            font-size: 15px;
            font-weight: 400;
            color: #111111;
        }
        
    }
}