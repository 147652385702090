.avt-reklama-big-title {
    color: #fff;
    text-align: center;
    font-size: 23px;
    font-weight: bold;
    line-height: 70px;
    text-transform: uppercase;
    background: #1d212d;
    background: -moz-linear-gradient(-45deg, #1d212d 0%, #232c45 100%);
    background: -webkit-linear-gradient(-45deg, #1d212d 0%,#232c45 100%);
    background: linear-gradient(135deg, #1d212d 0%,#232c45 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d212d', endColorstr='#232c45',GradientType=1 );
    margin: 0;
    @media(min-width: $breakpoint-tablet-min) {
        font-size: 40px;
        line-height: 140px;
    }
    @media(min-width: $breakpoint-desktop-min) {
        font-size: 75px;
        line-height: 255px;
    }
}