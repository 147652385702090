.article-page {
    h2 {
        font-size: 30px;
        font-weight: bold;
        line-height: 1.4;
        margin: 0 0 20px;
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 36px;
        }
        @media(min-width: $breakpoint-desktop-min) {
            font-size: 40px;
        }
    }
    
    .introtext {
        margin: 0 0 18px;
        font-weight: bold;
        font-size: 15px;
        line-height: 30px;
        margin: 0 0 20px;
        @media(min-width: $breakpoint-desktop-min) {
            font-size: 17px;
            line-height: 33px;
        }

        p {
            margin: 0 0 18px;
            @media(min-width: $breakpoint-desktop-min) {
                margin: 0 0 20px;
            }
        }

        > *:last-child {
            margin-bottom: 0 !important;
        }
    }
}