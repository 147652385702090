.avt-section-publicity {
    background-color: $bg-main;
    padding-top: 64px;
    padding-bottom: 64px;
    @media(min-width: $breakpoint-tablet-min) {
        padding-top: 0;
    }
    .avt-container-1400 {
        position: relative;
    }
    .avt-publicity-description {
        text-align: center;
        .type {
            color: $color-font-additional;
            font-size: 19px;
            font-weight: 700;
            margin-bottom: 32px;
            text-align: left;
        }
        .title {
            @media(min-width: $breakpoint-desktop-min){
                text-align: left;
                margin-bottom: 32px;
            }
            strong {
                color: $color-font-additional;
            }
        }
        .description {
            text-align: left;
        }
    }
    .avt-publicity-image {
        padding-top: 48px;
        padding-bottom: 48px;
        @media(min-width: $breakpoint-desktop-min) {
            padding: 0;
            position: absolute;
            right: 16px;
            top: 72px;
        }
        @media(min-width: $breakpoint-desktop-large-min) {
            top:-16px;
        }
        img {
            width: 100%;
            max-width: 700px;
            display: block;
            margin: 0 auto;
        }
    }
    .avt-button-main {
        margin: 0 auto;
    }
    
    &.reklama-page {
        padding-top: 64px;
        @media(min-width: $breakpoint-desktop-large-min) {
            padding-top: 150px;
            padding-bottom: 170px;
        }
        
        .avt-publicity-description {
            max-width: 1000px;
            margin: 0 auto;
            
            .title {
                text-align: center;
                
                strong {
                    color: #000;
                }
            }
            .description {
                text-align: center;
            }
        }
    }
}