.avt-anim-border {
    border-radius: 3px;
    position: relative;
    
    .text-border,
    .anim-text {
        position: relative;
        z-index: 2;
    }
    
    .anim-border {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        display: block;
        padding: 2px;
        border-radius: 3px;
        overflow: hidden;
    }
    
    .b {
        display: block;
        background: #6d6d6d;
        position: absolute;
    }
    
    .b-t {
        height: 2px;
        width: 100%;
        top: 0;
        left: -100%;
        transition: left 0.1s linear 0.3s;
    }
    
    .b-r {
        width: 2px;
        height: 100%;
        right: 0;
        top: -100%;
        transition: top 0.1s linear 0.2s;
    }
    
    .b-b {
        height: 2px;
        width: 100%;
        bottom: 0;
        right: -100%;
        transition: right 0.1s linear 0.1s;
    }
    
    .b-l {
        width: 2px;
        height: 100%;
        left: 0;
        bottom: -100%;
        transition: bottom 0.1s linear 0s;
    }
    
    &:hover:not(.border-input),
    &.border-input input:checked + .input  + .anim-border {
        .b-t {
            transition-delay: 0s;
            left: 0;
        }
        .b-r {
            transition-delay: 0.1s;
            top: 0;
        }
        .b-b {
            transition-delay: 0.2s;
            right: 0;
        }
        .b-l {
            transition-delay: 0.3s;
            bottom: 0;
        }
    }
}