.avt-category-bg {
    background-color: $bg-additional;
}
.avt-section-detail {
    background-color: $bg-home;
    overflow: hidden;
    padding-bottom: 3rem;
    @media(min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-desktop-large-min) {
        padding: 0 30px 3rem;
    }
    @media(min-width: $breakpoint-desktop-min) {
        position: relative;
        padding: 3% 4% 8%;
    }
    .avt-isometric-box {
        transform-style: preserve-3d;
        // perspective: 1000px;
        margin: -50px 0;
        @media(min-width: $breakpoint-tablet-min) {
            margin: -70px 0 -125px;
        }
        @media(min-width: $breakpoint-desktop-min) {
            position: absolute;
            right: -20%;
            top:10%;
        }
        @media(min-width: $breakpoint-desktop-xxl-min) {
            top:-8%;
        }
        .avt-isometric-item {
            box-shadow: -41px 45px 64px 4px rgba(0,0,0,0.35);
            width: 65vw;
            margin-left: 18vw;
            outline: 1px solid transparent;
            perspective: 3000px;
            transform-style: preserve-3d;
            transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg);
            position: relative;
            top: -15%;
            @media(min-width: $breakpoint-mobile-min){
                width: 45vw;
                margin-left: 26vw;
            }
            @media(min-width: $breakpoint-tablet-min){
                top:0px;
            }
            @media(min-width: $breakpoint-desktop-min) {
                width: 40vw;
            }
            img{
                width: 100%;
            }
            &:before, .avt-long {
                content: "";
                display: block;
                transform-origin: 0 0;
                background: inherit;
                background-size: cover;
                top:0;
                left:0;
                width: 3px;
                height: 100%;
                transform: rotateY(100deg);
                position: absolute;
                outline: 1px solid transparent;
                @media(min-width: $breakpoint-mobile-min) {
                    width: 5px;
                }
                @media(min-width: $breakpoint-tablet-min) {
                    width: 7.5px;
                }
                @media(min-width: $breakpoint-desktop-min) {
                    width: 10px;
                }
            }
            .avt-long {
                background: $color-black-0;
                opacity: 0.5;
            }
            &:after, .avt-short {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 3px;
                bottom:0;
                background: inherit;
                background-size: cover;
                outline: 1px solid transparent;
                transform-origin: 0 100%;
                transform: rotateX(90deg);
                @media(min-width: $breakpoint-mobile-min) {
                    height: 5px;
                }
                @media(min-width: $breakpoint-tablet-min) {
                    height: 7.5px;
                }
                @media(min-width: $breakpoint-desktop-min) {
                    height: 10px;
                }
            }
            .avt-short {
                z-index: 999;
                background-color: $color-white;
                opacity: 0.5;
            }
        }
        &.disable-iso-border {
            .avt-isometric-item {
                &:before {
                    display: none;
                }
                .avt-long {
                    display: none;
                }
            }
        }
    }
    .avt-breadcrumb {
        li {
            color: $color-font-second;
            display: none;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            font-size: 15px;
            margin-top: 24px;
            margin-bottom: 16px;
            @media(min-width: $breakpoint-desktop-min){
                display: inline-block;
            }
            &:last-child {
                display: block;
                @media(min-width: $breakpoint-tablet-min) {
                    text-align: left;
                }
                @media(min-width: $breakpoint-desktop-min){
                    display: inline-block;
                }
            }
            a {
                color: $color-font-second;
            }
            .divider {
                padding: 0 5px;
            }
            &.active {
                display: none;
            }
        }
    }
    .avt-section-title {
        font-size: 25px;
        color: $color-font-second;
        text-align: center;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(255,255,255,0.4);
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 50px;
            text-align: left;
        }
    }
    .avt-title-link {
        text-align: center;
        display: block;
        color: $color-font-second;
        text-decoration: none;
        margin-top: 16px;
        @media(min-width: $breakpoint-tablet-min) {
            float: left;
        }
    }
    .avt-social-media-box {
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            width: 25px;
            margin: 24px 16px;
            color: $color-white;
            text-decoration: none;
            svg {
                width: 100%;
                fill: $color-white;
            }
        }
        @media(min-width: $breakpoint-tablet-min) {
            float: right;
            a{
                margin: 10px 3px 0;
                &:last-child {
                margin-right: 0;
                }
            }
        }
    }
    .avt-description {
        color: $color-font-second;
        font-size: 15px;
        opacity: 0.5;
        padding-top: 28px;
        margin-bottom: 40px;
        @media(min-width: $breakpoint-tablet-min) {
            padding-top: 38px;
            margin-bottom: 50px;
            clear: both;
            font-size: 18px;
        }
    }
    .avt-statistic {
        font-size: 0;
        .avt-statistic-item {
            text-align: center;
            margin-bottom: 24px;
            @media(min-width: $breakpoint-tablet-min) {
                display: inline-block;
                width: 50%;
                text-align: left;
            }
            @media(min-width: $breakpoint-desktop-large-min) {
                width: auto;
                margin-right: 3%;
            }
            .avt-number, .avt-text {
                color: $color-font-second;
                display: block;
                font-weight: 700;
            }
            .avt-number{
                font-size: 27px;
                @media(min-width: $breakpoint-desktop-large-min) {
                    font-size: 22px;
                }
            }
            .avt-desc {
                color: #9a9a9a;
                font-weight: 300;
                font-size: 11px;
            }
            .avt-text {
                font-size: 15px;
            }
        }
    }
    .avt-app-btn-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        max-width: 300px;
        margin: 0 auto;
        @media(min-width: $breakpoint-tablet-min) {
            max-width: 500px;
        }
        .avt-app-button {
            @media(min-width: $breakpoint-tablet-min) {
                max-width: 500px;
                margin-left: 24px;
            }
        }
    }
    .avt-info-box {
        @media(min-width: $breakpoint-tablet-min) {
            padding-bottom: 34px;
            margin-bottom: 24px;
            &:after{
                content: "";
                display: block;
                position: absolute;
                bottom: -1px;
                width: calc(100% - 30px);
                margin: 0 auto;
                background-color: rgba(255,255,255,0.4);
                height: 1px;
            }
        }
    }
}
.avt-wrap {
    @media(max-width: $breakpoint-mobile-max){
        padding-top: 16px;
        border-top: 1px solid rgba(255,255,255,0.4);
        margin: 16px auto 0;
    }
    @media(min-width: $breakpoint-tablet-min) {
        float: right;
    }
}
.avt-portfolio-button {
    border-radius: 5px;
    border-width: 1px;
    border-color:#919191;
    border-style: solid;
    font-size: 13px;
    background-color: #000;
    color: $color-font-second;
    text-align: center;
    height: 40px;
    line-height: 40px;
    max-width: 300px;
    margin: 0 auto;
    position: relative;
    @media(min-width: $breakpoint-tablet-min) {
        float: left;
        width: 320px;
        max-width: none;
    }
    &:hover {
        color: $color-font-second;
    }
    strong {
        color: $color-font-second;
        font-weight: 700;
        text-transform: uppercase;
    }
    img {
        margin-top: -3px;
    }
}
.avt-section-portfolio-details {
    position: relative;
    background-color: $bg-second;
    overflow: hidden;
    z-index: 0;
    .avt-category-box {
        background-color: $bg-additional;
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(255,255,255,0.8);
        cursor: pointer;
        @media(min-width: $breakpoint-tablet-min) {
            border: none;
        }
        @media(min-width: $breakpoint-desktop-min) {
            padding-top: 5px;
            padding-bottom: 5px;
        }
        &:last-child {
            border-bottom: none;
        }
        .avt-image {
            padding-right: 0;
            text-align: right;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        svg {
            width: 45px;
            fill: #fff;
            line, rect, polygon, circle {
                stroke: #fff;
            }
        }
        .avt-type, .avt-title {
            @media(min-width: $breakpoint-tablet-min){
                text-align: center;
            }
        }
        .avt-type {
            color: $color-font-additional;
            font-size: 17px;
            font-weight: 700;
            @media(min-width: $breakpoint-tablet-min) {
                font-size: 14px;
                margin-bottom: 0;
                text-align: left;
            }
        }
        .avt-title {
            font-size: 23px;
            font-weight: 700;
            color: $color-font-second;
            @media(min-width: $breakpoint-tablet-min) {
                font-size: 18px;
                text-align: left;
            }
        }
        &.active {
            background-color: $color-red;
            border: none;
            @media(min-width: $breakpoint-tablet-min) {
                background-color: $bg-second;
            }
            svg {
                @media(min-width: $breakpoint-tablet-min){
                    fill: $color-font-main;
                    line, rect, polygon, circle {
                        stroke: $color-font-main;
                    }
                }
            }
            .avt-type {
                color: $color-font-second;
                @media(min-width: $breakpoint-tablet-min) {
                    color: $color-font-additional;
                }
            }
            .avt-title {
                @media(min-width: $breakpoint-tablet-min) {
                    color: $color-font-main;
                }
            }
        }
    }
    .avt-prev-but-circle, .avt-next-but-circle {
        width: 130px;
        height: 130px;
        background-color: $bg-main;
        position: absolute;
        border-radius: 50%;
        margin-top: 45%;
        z-index: 999;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        @media(min-width: $breakpoint-tablet-min) {
            margin-top: 15%;
        }
        transition-duration: 400ms;
        span {
            border-radius: 50%;
            display: block;
            height: 100%;
            width: 100%;
            z-index: 999;
            i {
                height: 24px;
                font-size: 24px;
                transition-duration: 400ms;
                display: block;
                position: absolute;
                width: 24px;
                left:10px;
                bottom:0;
                top:0;
                margin: auto;
            }
        }
    }
    .avt-prev-but-circle {
        left: -90px;
        @media(min-width: $breakpoint-desktop-large-min) {
            &:hover {
                left: -70px;
            }
        }
        span {
            i {
                left: auto;
                right: 10px;
            }
        }
    }
    .avt-next-but-circle {
        right: -90px;
        @media(min-width: $breakpoint-desktop-large-min) {
            &:hover {
                right: -70px;
            }
        }
    }
    .avt-newspaper-detail {
        padding-top: 72px;
        display: none;
        &.active {
            display: block;
        }
        .avt-newspaper-image {
            margin-bottom: 32px;
            .online-image-wrapper {
                position: relative;
                .online-image {
                    position: relative;
                    img {
                        width: 100%;
                    }
                    picture {
                        img {
                            width: 100%;
                            box-shadow: none;
                            position: absolute;
                            top: 0;
                            left: 0;
                            padding: 4%;
                        }
                    }
                }
                .print-image {
                    position: relative;
                    img {
                        width: 100%;
                    }
                    picture {
                        img {
                            width: 100%;
                            box-shadow: none;
                            position: absolute;
                            top: 0;
                            left: 0;
                            padding: 15% 20%;;
                        }
                    }
                }

            }
            picture {
                img {
                    width: 75%;
                    display: block;
                    margin: 0 auto;
                    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
                }
            }
        }
        .avt-newspaper-info {
            .avt-type {
                font-size: 15px;
                font-weight: 400;
            }
            & > .avt-title {
                font-size: 24px;
                font-weight: 700;
                padding-bottom: 8px;
                border-bottom: 4px solid $color-red;
            }
            .avt-links {
                & > .avt-title {
                    font-size: 18px;
                    text-transform: uppercase;
                    font-weight: 700;
                    margin: 16px 0;
                    svg {
                        fill: $color-red;
                        width: 16px;
                    }
                }
                .avt-link {
                    display: block;
                    color: $color-font-main;
                    font-size: 16px;
                    margin: 8px 0;
                    svg {
                        vertical-align: middle;
                        width: 16px;
                        fill: $color-red;
                    }
                }
            }
        }
    }
    .avt-additional-info-box {
        .avt-additional-info {
            padding-bottom: 32px;
            .avt-title {
                font-size: 20px;
                font-weight: 700;
                color: $color-font-additional;
                padding-bottom: 12px;
                margin-bottom: 16px;
                border-bottom: 2px solid rgba(0,0,0,0.2);
            }
            .avt-info {
                font-size: 15px;
                line-height: 33px;
                p {
                    @media(min-width: $breakpoint-desktop-min) and (max-width: 1439.8px) {
                        padding-right: 40px;
                    }
                }
                .avt-value {
                    font-weight: 700;
                }
            }
        }
        .avt-contact-box {
            display: flex;
            margin-bottom: 24px;
            &>div {
                width: 100%;
            }
            .avt-photo {
                img {
                    width: 100%;
                    background-color: $bg-main;
                    border-radius: 50%;
                }
            }
            .avt-info {
                .avt-name, .avt-position, .avt-tel, .avt-mail {
                    display: block;
                    line-height: 1.5;
                    color: $color-font-main;
                    text-decoration: none;
                }
                .avt-name {
                    font-size: 17px;
                    font-weight: 700;
                }
                .avt-position {

                }
                .avt-mail {
                    color: $color-font-additional;
                }
                .avt-tel {

                }
            }
        }
        .avt-channel-box {
            padding-bottom: 32px;
            a {
                text-decoration: none;
            }
            .avt-image {
                margin-bottom: 16px;
                text-align: center;
                .avt-pic {
                    display: inline-block;
                    width: 92px;
                    height: 92px;
                    border-radius: 50%;
                    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
                    background: #fff;
                    img{
                        display: block;
                        margin: 30px auto 0;
                        @media (min-width: $breakpoint-desktop-min) {
                            // margin: 0;
                        }
                    }
                }
            }
            .avt-link {
                display: block;
                font-size: 17px;
                border: none;
                padding: 0;
                margin: 0;
                text-align: center;
                font-weight: 700;
                color: #d32e2f;
                text-decoration: none;
            }
            .avt-description {
                .avt-title, .avt-additional-info {
                    @media (min-width: $breakpoint-desktop-min) {
                        // text-align: left;
                    }
                }
            }
            .avt-title, .avt-additional-info {
                border: none;
                padding: 0;
                margin: 0;
                text-align: center;
            }
            .avt-title {
                font-size: 17px;
            }
            .avt-additional-info {
                display: block;
                line-height: 1.5;
            }
        }
        .avt-specification {

            @media(min-width: $breakpoint-mobile-min){
                font-size: 17px;
            }
            .avt-name {
                display: inline-block;
            }
            .avt-value {
                display: inline-block;
                float: right;

            }
        }
        .avt-circle-icons {
            .avt-circle {
                margin-bottom: 16px;
                .avt-circle-icon {
                    @media(min-width: $breakpoint-tablet-min) {
                        margin-bottom: 16px;
                    }
                }
            }
            .avt-type, .avt-value {
                display: block;
                width: 100%;
                text-align: left;
                @media(min-width: $breakpoint-tablet-min) {
                    text-align: center;
                }
                .avt-br-mobile {

                }
            }
            .avt-distribution-info {
                .avt-title {
                    text-align: center;
                    margin: 32px 0 16px;
                    color: $color-font-main;
                    font-size: 15px;
                    border: none;
                    strong {
                        display: block;
                        font-size: 25px;
                        color: $color-font-additional;
                    }
                }
            }
        }
        .avt-chart {
            iframe{
                width: 100%;
            }
        }
        .avt-line-box {
            position: relative;
            @media(min-width: $breakpoint-tablet-min) {
                width: 67.5%;
                height: 30px;
                margin: 0 auto;
                border: 1px solid rgba(0,0,0,0.2);
                border-top: none;
            }
            &:after {
                @media(min-width: $breakpoint-tablet-min) {
                    content: "";
                    display: block;
                    position: absolute;
                    height: 30px;
                    width: 1px;
                    left: 0;
                    right: 0;
                    bottom: -15px;
                    margin: auto;
                    background-color: rgba(0,0,0,0.2);
                }
            }
        }
    }
}
.avt-section-header {
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    padding-bottom: 12px;
    border-bottom: 2px solid $color-black;
    @media(min-width: $breakpoint-desktop-min) {
        text-align: left;
    }
}
.avt-section-news {
    .avt-news {
        text-align: center;
        padding-bottom: 24px;
        @media(min-width: $breakpoint-tablet-min) {
            margin: 16px 0 32px;
            padding-bottom: 0px;
        }
        .col-first {
            max-width: 330px;
            @media (max-width: $breakpoint-mobile-max) {
                flex: none;
                display: block;
                margin: 0 auto;
            }
            @media (min-width: $breakpoint-tablet-min) {
                text-align: left;
                -ms-flex: 0 0 330px;
                flex: 0 0 330px;
            }
        }
        .col-second {
            @media (max-width: $breakpoint-mobile-max) {
                flex: none;
            }
            @media (min-width: $breakpoint-desktop-min) {
                padding-left: 90px;
            }
        }
        .avt-img {
            margin: 16px 0 32px;
            @media(min-width: $breakpoint-tablet-min) {
                margin: 0;
            }
            .img-w {
                display: inline-block;
                border: 1px solid #d5d5d5;
                padding: 9px;
                height: 300px;
                font-size: 0;
                &:before {
                    display: inline-block;
                    content: '';
                    font-size: 0;
                    height: 100%;
                    vertical-align: middle;
                }
            }
            img {
                max-width: 500px;
                margin: 0 auto;

            }
        }
        .text-link {
            &:hover {
                .avt-title {
                    color: $color-font-additional;
                }
            }
        }
        .avt-date {
            display: block;
            color: $color-font-additional;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 16px;
            @media(min-width: $breakpoint-tablet-min) {
                text-align: left;
            }
        }
        .avt-title {
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            margin-bottom: 40px;
            @media(min-width: $breakpoint-tablet-min) {
                text-align: left;
                margin-bottom: 16px;
            }
            @media(min-width: $breakpoint-desktop-min) {
                font-size: 30px;
                line-height: 45px;
            }
        }
        .avt-description {
            text-align: left;
            color: $color-font-main;
        }
        .avt-button-read {
            color: $color-font-main;
            text-decoration: none;
            display: block;
            text-align: left;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 13px;
            svg {
                fill: $color-red;
                margin-bottom: 1px;
                width: 15px;
                display: inline-block;
            }
        }
    }
}

.avt-section-marks {
    background-color: $bg-second;
    .avt-mark-box {
        padding-bottom: 16px;
        .avt-mark-logo {
            background-color: $bg-main;
            min-height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            margin: 8px 0 24px;
            img {
                width: 90%;
                display: block;
                margin: 0 auto;
            }
        }
        .avt-mark-description {
            font-size: 16px;
            line-height: 30px;
        }
    }
}
.avt-section-photo {
    .avt-photo-box {
        a{
            text-decoration: none;
        }
        .avt-image {
            display: block;
            margin: 0 auto;
            width: 100%;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        }
        .avt-title {
            font-size: 18px;
            font-weight: 600;
            text-align: left;
            margin: 16px 0;
        }
    }
}
