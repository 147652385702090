.avt-section-portfolio-main {
    background-color: $bg-home;
    .avt-portfolio-header {
        text-align: center;
        color: $color-font-second;
        font-weight: 700;
        font-size: 60px;
        padding: 56px 0;
        @media(min-width: $breakpoint-tablet-min){
            font-size: 78px;
            padding-top: 96px;
            padding-bottom: 24px;
        }
    }
    .avt-header-line {
        .avt-header{
            &:after, &:before{
                border-color: $color-white;
            }
            span{
                color: $color-font-second;
            }
        }
    }
}
.avt-section-portfolio-description {
    .avt-title {
        font-size: 40px;
        margin-bottom: 48px;
    }
    .avt-recommended {

        img{
            display: block;
            margin: 0 auto;
            width: 100%;
            box-shadow: 3px 5px 20px 0px rgba(0, 0, 0, 0.19), 4px 7px 17px 0px rgba(0, 0, 0, 0.2);
        }
        .avt-download {
            border-bottom: 4px solid $color-font-additional;
            display: block;
            margin-top: 32px;
            padding-bottom: 8px;
            text-decoration: none;
            .avt-download-link {
                font-size: 19px;
                font-weight: 700;
                text-transform: uppercase;
            }
            svg{
                width: 18px;
                margin-right: 5px;
            }
        }
        .avt-info{
            display: block;
            margin-top: 8px;
            color: $color-font-main;
            text-decoration: none;
            svg{
                width: 18px;
                margin-right: 5px;
                fill: $color-font-additional;
            }
        }
    }
}
