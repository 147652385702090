.avt-data-box {
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    @media(min-width: $breakpoint-tablet-min){
        border-right: 1px solid rgba(255,255,255,0.1);
    }
    &:last-child{
        border: none;
    }
    .avt-type {
        color: $color-font-additional;
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
        align-items: flex-end;
    }
    .avt-title {
        color: $color-font-second;
        font-size: 23px;
        line-height: 25px;
        font-weight: 700;
        display: flex;
        align-items: center;
        @media(min-width: $breakpoint-tablet-min){
            text-align: center;
        }
        @media(min-width: $breakpoint-desktop-large-min){
            text-align: left;
        }
    }
    .avt-number {
        color: $color-font-second;
        font-size: 60px;
        line-height: 50px;
        font-weight: 700;
        padding-right: 0;
        text-align: right;
        @media(min-width: $breakpoint-tablet-min){
            padding-right: 15px;
            padding-top: 16px;
            padding-bottom: 16px;
        }
        @media(min-width: $breakpoint-desktop-large-min) {
            padding: 0;
        }
    }
    .avt-subtitle {
        color: $color-font-second;
        font-size: 15px;
        font-weight: 300;
        text-align: left;
        opacity: 0.7;
        padding-left: 0;
        @media(min-width: $breakpoint-desktop-xxl-min) {
            padding-left: 5px;
        }
    }
}
