.contact-page {
    font-size: 15px;
    @media(min-width: $breakpoint-desktop-min) {
        font-size: 20px;
    }
    main {
        background: #F1F1F1;
    }
    
    .map {
        position: relative;
        height: 400px;
        max-height: 85vh;
        margin-left: -15px;
        margin-right: -15px;
        @media(min-width: $breakpoint-tablet-min) {
            margin-right: 0;
            max-height: none;
        }
        @media(min-width: $breakpoint-desktop-min) {
            height: 100%;
        }
        
        iframe {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    
    .avt-info {
        padding: 28px 0;
        max-width: 420px;
        @media(min-width: $breakpoint-desktop-min) {
            max-width: 480px;
            padding: 110px 0;
        }
        @media(min-width: $breakpoint-desktop-large-min) {
            margin: 0 0 0 80px;
        }
    }
    
    h2 {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
    }
    
    p {
        margin: 0;
        line-height: 27px;
        @media(min-width: $breakpoint-desktop-min) {
            line-height: 30px;
        }
    }
    
    .contact-slider {
        margin: 32px auto;
        font-size: 15px;
        @media(min-width: $breakpoint-desktop-min) {
            margin: 55px auto;
            font-size: 20px;
        }
        
        .avt-name {
            font-weight: bold;
            font-size: 15px;
            margin: 0;
            @media(min-width: $breakpoint-desktop-min) {
                font-size: 20px;
            }
        }
        
        .avt-mail {
            color: #d32e2f;
        }
        
        .avt-tel {
            color: #25292f;
        }
    }
}