/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1025px,
  xl: 1200px
) !default;

@at-root {
  @-ms-viewport { width: device-width; } // stylelint-disable-line at-rule-no-vendor-prefix
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../../node_modules/bootstrap/scss/mixins/grid-framework";
@import "../../../node_modules/bootstrap/scss/mixins/grid";

@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/utilities/display";
@import "../../../node_modules/bootstrap/scss/utilities/flex";


@import "../../../node_modules/bootstrap/scss/mixins/hover";
@import "../../../node_modules/bootstrap/scss/_reboot";

@import "../../../node_modules/bootstrap/scss/mixins/clearfix";
@import "../../../node_modules/bootstrap/scss/utilities/clearfix";

@import "../../../node_modules/bootstrap/scss/mixins/float";
@import "../../../node_modules/bootstrap/scss/utilities/float";
@import "../../../node_modules/bootstrap/scss/utilities/spacing";
@import "../../../node_modules/bootstrap/scss/utilities/position";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/utilities/embed";

@import "../../../node_modules/bootstrap/scss/transitions";

/* Transition */
@import "../../../node_modules/bootstrap/scss/mixins/transition";