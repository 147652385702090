.materials-page {
    .anchors-wrap {
        height: 38px;
    }
    .materials-anchors {
        text-align: center;
        background: #fff;
        z-index: 200;
        
        li {
            display: inline-block;
            margin: 4px 5px;
            line-height: 30px;
            vertical-align: middle;
            @media(min-width: $breakpoint-desktop-min) and (max-width: $breakpoint-desktop-max) {
                margin: 4px 0;
            }
            
            .anchor {
                display: block;
                position: relative;
                padding: 0 5px;
                font-size: 13px;
                font-weight: 500;
                cursor: pointer;
            }
        }
        
        &.fixed {
            position: fixed;
            top: 0;
            box-shadow: 0 2px 6px #b7b7b7;
            @media(max-width: $breakpoint-tablet-max) {
                left: 0;
                right: 0;
            }
            @media(min-width: $breakpoint-desktop-min) {
                left: calc(25% + 15px);
                right: 15px;
            }
            @media(min-width: $breakpoint-desktop-large-min) {
                left: calc((100% - 1200px)/2 + 300px + 15px);
                right: calc((100% - 1200px)/2 + 15px);
            }
        }
    }
    
    h2 {
        margin: 34px 0 0;
        border-bottom: 1px solid #DEDEDE;
        padding: 0 0 4px;
        font-size: 30px;
        font-weight: bold;
    }
    
    .materials-files {
        margin: 18px 0 0;
        li {
            padding: 0 0 0 12px;
            position: relative;
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: "\25AA";
                color: #D51B19;
            }
            
            + li {
                margin: 14px 0 0;
            }
            
            a {
                font-size: 15px;
                
                
                .info {
                    font-size: 13px;
                }
            }
        }
    }
}