.avt-anim-line {
    > li {
        > a {
            position: relative;
            text-decoration: none;
            
            &:after{
                content: "";
                position: absolute;
                left: 0;
                bottom: -1px;
                width: 0;
                height: 3px;
                background-color: $color-red;
                -webkit-transition: width .25s ease-out 0s;
                transition: width .25s ease-out 0s;
            }

            &:hover {
                &:after{
                    width: 100%;
                }
            }
        }
    }
}