.avt-text-block {
    font-size: 15px;
    line-height: 30px;
    @media(min-width: $breakpoint-desktop-min) {
        font-size: 17px;
        line-height: 33px;
    }

    p {
        margin: 0 0 18px;
        @media(min-width: $breakpoint-desktop-min) {
            margin: 0 0 20px;
        }
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto 18;
        @media(min-width: $breakpoint-desktop-min) {
            margin: 0 auto 20px;
        }
    }
    
    h3,
    h4,
    h5, {
        margin: 26px 0 18px;
        font-weight: bold;
        @media(min-width: $breakpoint-desktop-min) {
            margin: 28px 0 20px;
        }
    }
    
    h3 {
        font-size: 26px;
    }
    
    h4 {
        font-size: 22px;
    }
    
    h5 {
        font-size: 20px;
    }
    
    ul {
        margin: 0 0 18px;
        @media(min-width: $breakpoint-desktop-min) {
            margin: 0 0 20px;
        }
        
        li {
            position: relative;
            padding: 0 0 0 20px;
            
            &:before {
                display: inline;
                content: '\25CF';
                position: absolute;
                top: 0;
                left: 0;
                line-height: 1.8;
            }
            
            p {
                margin: 0;
            }
            
            + li {
                margin: 10px 0 0;
            }
        }
    }
    
    > *:last-child {
        margin-bottom: 0 !important;
    }
}

.avt-text-block-2 {
    font-size: 15px;
    line-height: 30px;
    @media(min-width: $breakpoint-desktop-min) {
        font-size: 20px;
        line-height: 33px;
    }
    
    p {
        margin: 0 0 18px;
        @media(min-width: $breakpoint-desktop-min) {
            font-size: 20px;
        }
    }
    
    > *:last-child {
        margin-bottom: 0 !important;
    }
}