.avt-isometric-grid{
    padding: 0;
    position: relative;
    left: 20vw;
    top: -0vh;
    overflow: hidden;
    width: 70vw;
    min-height: 300vh;
    transform-style: preserve-3d;
    transform: rotateX(35deg) rotateY(3deg) rotateZ(27deg);
    z-index: 0;
    @media(min-width: $breakpoint-desktop-min) {
        left: 10vw;
    }
    @media(min-width: $breakpoint-desktop-min) {
        left: 20vw;
        top: -20vh;
    }
    @media(min-width: 1400px){
        top:0;
        left: 20vw;
        width: 67vw;
    }
    &:after{
        content: "";
        display: block;
        position: absolute;
        background-color: lime;
        top: 0;
        right: -30px;
        bottom: 0;
        width: 30px;
    }
    .row {
        position: absolute;
        left: 0px;
        top:21%;
        width: calc(100% + 32px);
        overflow-y: scroll;
        height: 180vh;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        transform-style: preserve-3d;
        padding-left: 25vw;
        @media(min-width: 1400px){
            padding-left: 6vw;
        }
    }
    .avt-isometric-item {
        perspective: 300px;
        margin-bottom: 7.5px;
        padding-left: 0;
        padding-right: 7.5px;
        transform-style: preserve-3d;
        z-index: 0;
        outline: 1px solid transparent;
        position: relative;
        .avt-isometric-title {
            position: absolute;
            bottom: 0;
            left:0;
            right:0;
            font-weight: 500;
            text-align: center;
            color: $color-font-second;
            opacity: 0;
            transition: all 0.3s ease-in-out;
        }
        @media(min-width: 1400px) {
            flex: 0 0 25%;
        }
        .avt-isometric-image {
            display: block;
            outline: 1px solid transparent;
            backface-visibility: none;
            transition: all 0.3s ease-in-out;
            box-shadow: 1px 1px 3px 0 #000;
        }
        &:hover {
            @media(min-width: $breakpoint-desktop-min) {
                a{
                    box-shadow: 71px 71px 65px -17px rgba(0, 0, 0, .7);
                    transform: translateX(-60px) translateY(-95px);
                }
                .avt-isometric-title {
                    opacity: 1;
                }
            }
        }

        img {
            z-index: 0;
            width: 100%;
            outline: 1px solid transparent;
            backface-visibility: none;
        }
    }
    .avt-isometric-marginBox {
        // display: none;
        &.avt-isometric-top {
            height: 400px;
        }
        &.avt-isometric-bot {
            height: 300px;
        }
    }
    #isometric-wrap {
        display: flex;
        flex-wrap: wrap;
    }
}
